/*
 * @Author: huangzq
 * @Date: 2020-10-15 11:35:57
 * @Last Modified by: huangzq
 * @Last Modified time: 2020-10-15 17:19:31
 * @Desc: 弹窗播放视频组件
*/
<template>
  <div class="tabbar-box box">
    <div
      v-for="(item, index) in tabbar"
      :key="index"
      class="tab-bar-item"
      :class="activeIndex === index ? 'active-item' : ''"
      @click.stop="goUrl(item)"
    >
      <img
        :src="activeIndex === index ? item.active : item.icon"
        class="tab-img"
        alt=""
      >
      <div class="view-box">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupPlayVideo',
  props: {
    activeIndex: {
      type: Number,
      default: -1
    },
    controls: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      newVideoUrl: '',
      tabbar: [
        {
          name: '首页',
          icon: require('@/assets/img/icon-home.png'),
          active: require('@/assets/img/icon-home-a.png'),
          url: '/home/index'
        },
        {
          name: '商品',
          icon: require('@/assets/img/icon-course.png'),
          active: require('@/assets/img/icon-course-a.png'),
          url: '/home/goods'
        },
        {
          name: '我的',
          icon: require('@/assets/img/icon-my.png'),
          active: require('@/assets/img/icon-my-a.png'),
          url: '/user/index'
        }
      ]
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        opened: () => {
          this.opened()
        },
        closed: () => {
          this.closed()
        }
      }
    }
  },

  methods: {
    goUrl (item) {
      this.$router.replace(item.url)
    },
    opened () {
      this.newVideoUrl = this.videoUrl
      this.$nextTick(() => {
        if (this.$refs.videoRef) this.$refs.videoRef.play()
      })
    },
    closed () {
      this.newVideoUrl = ''
      this.$nextTick(() => {
        if (this.$refs.videoRef) this.$refs.videoRef.pause()
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.tabbar-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 48px;
  background: white;
  display: flex;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 98;
  box-shadow: inset 0px 1px 0px 0px rgba(219,219,219,0.5);

  .tab-bar-item {
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #8F92A9;
  }
  .tab-bar-item .view-box {
    font-size: 12px;
  }

  .tab-bar-item .tab-img {
    width: 24px;
    height: 24px;
  }
  .active-item {
    color: #4A77FF;
  }
}
</style>
