<template>
  <div
    class="page-box box"
    @scroll="getScroll($event)"
  >
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
    >
      <!-- 输入框 -->
      <van-search
        v-model="searchName"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div
            class="hr-btn"
            @click="onSearch"
          >
            搜索
          </div>
        </template>
      </van-search>

      <!-- 分类 -->
      <div class="type-box">
        <van-grid
          :border="false"
          :column-num="4"
        >
          <van-grid-item
            v-for="(item, index) of showClassifyList"
            :key="index"
            @click="goType(index, item)"
          >
            <div
              v-if="index <= 7"
              class="type-item"
            >
              <template v-if="index === 7">
                <!-- <img src="https://test.tjerong.cn/images/org/1626429952356.png"> -->
                <img src="https://erong-qtj.oss-cn-beijing.aliyuncs.com/icons/quanbu.png" />
                <p>全部</p>
              </template>
              <template v-else>
                <img :src="item.img" />
                <p>{{ item.classifyName }}</p>
              </template>
            </div>
          </van-grid-item>
        </van-grid>
      </div>

      <!-- 列表 -->
      <div class="list-box box">
        <van-tabs
          v-model="activeName"
          @change="tabChange"
        >
          <template v-for="item in typeArr">
            <van-tab
              :key="item.classifyNo"
              :title="item.classifyName"
              :name="item.classifyNo"
            ></van-tab>
          </template>
        </van-tabs>
        <div class="list-in-box box">
          <!-- https://cf-workphone.oss-cn-hangzhou.aliyuncs.com/wpUpload/88agoh4nqjn1671277520200.png -->
          <div
            v-for="item in schoolList"
            :key="item.schoolNo"
            class="item-box box"
            @click="goSchoolDetail(item)"
          >
            <div class="img-box">
              <van-image :src="item.img">
                <template #error>
                  图片加载失败
                </template>
              </van-image>
            </div>
            <div class="text-a">
              <!-- <div class="tag-b tag">门店活动</div> -->
              <div class="text">
                {{ item.courseName }}
              </div>
              <div
                class="tag-a tag"
              >
                {{ item.subject }}
              </div>
            </div>
            <div class="price-box box">
              <div class="price-a">
                ￥{{ item.amount || 0 }}
              </div>
              <div class="price-b">
                原价￥{{ item.price || 0 }}
              </div>
            </div>
          </div>
          <div
            v-show="loading"
            class="end-text box"
          >
            加载中...
          </div>
          <div
            v-show="finished"
            class="end-text box"
          >
            没有更多了
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <tabbar :active-index="1"></tabbar>
  </div>
</template>

<script>
import tabbar from '@/components/common/tabbar.vue'
export default {
  name: 'Test',
  components: {
    tabbar,
  },
  data () {
    return {
      activeName: '',
      searchName: '',
      current: 0,
      bannerList: [],
      info: {},
      classifyList: [],
      showClassifyList: [],
      pageSize: 10,
      pageNo: 1,
      schoolList: [],
      isLoading: false,
      loading: false,
      total: 0,
      finished: false,
      loaded: false,
      typeArr: [
        {classifyName: '文化', classifyNo: '1', img: 'https://erong-qjy.oss-cn-beijing.aliyuncs.com/wechat/zi.png'},
        {classifyName: '素养', classifyNo: '2', img: 'https://erong-qjy.oss-cn-beijing.aliyuncs.com/wechat/ju.png'},
        {classifyName: '其他商品', classifyNo: '3', img: 'https://erong-qjy.oss-cn-beijing.aliyuncs.com/wechat/huang.png'}
      ]
    }
  },
  computed: {
  },
  async created () {
    await this.getClassifies()
    let query = this.$route.query
    this.pageNo = 1
    this.schoolList = []
    this.loaded = true
    if (query.classifyNo) {
      this.activeName = query.classifyNo 
    }
    await this.getCourseList({msg: 'update'})
  },
  methods: {
    async getClassifies () {
      let res = await this.$http.homeApi.getClassifies({})
      console.log(res, '类型')
      if (res.code === 200) {
        this.typeArr = res.msg || []
      }
    },
    tabChange (e) {
      this.pageNo = 1
      this.schoolList = []
      this.activeName = e
      console.log(e, 'eeee')
      if (this.loaded) {
        this.getCourseList()
      }
    },
    onSearch () {
      this.pageNo = 1
      this.schoolList = []
      this.getCourseList()
    },
    getLocation () {
    },
    onChange(index) {
      this.current = index;
    },
    async getCourseList (data = {}) {
      let vm = this
      if (!this.loading || data.msg === 'update') {
        this.loading = true;
        console.log(this.activeName, 'this.activeName1')
        let data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          classifyNo: this.activeName,
          content: this.searchName,
          lat: window.localStorage.getItem('latitude') || '',
          lng: window.localStorage.getItem('longitude') || ''
        }
        this.pageNo ++
        try {
          let res = await this.$http.homeApi.courseList(data)
          console.log(res, 'list')
          if (res.code === 200) {
            console.log(res, 'res')
            let arr = this.$deepClone(this.schoolList)
            for (let i of res.msg.data || []) {
              arr.push(i)
            }
            this.schoolList = arr
            this.total = res.msg.total
            this.finished = this.schoolList.length >= res.msg.total
          }
          this.loading = false
        } catch (e) {
          vm.loading = false
        }
      }
    },
    async getBannerInfo () {
      console.log('bannerinfo')
      let res = await this.$http.homeApi.bannerInfo({})
      console.log(res, 'res===')
      if (res.code === 200) {
        console.log(res, 'res')
        let data = res.msg || []
        // this.info = data
        data.push(data[0])
        this.bannerList = data
        // this.classifyList = data.classifyList
        // this.showClassifyList = data.classifyList.slice(0,8)
        // this.$store.commit('setClassifyList', data.classifyList)
        try {
          window.localStorage.setItem('homeClassifyList', JSON.stringify(data.classifyList))
        } catch (e) {
          console.log(e)
        }
      }
    },
    async onRefresh () {
      await this.getBannerInfo()
      this.schoolList = []
      this.pageNo = 1
      await this.getCourseList()
      this.isLoading = false
    },
    goType (index, item) {
      this.$router.push(`/home/type?index=${index}&classifyNo=${item.classifyNo || ''}`)
    },
    goSchoolDetail (item) {
      this.$router.push(`/goods/detail?id=${item.courseNo || ''}`)
    },
    // 处理滚动条滚到底部加载数据
    getScroll (event) {
      let nScrollHight = 0
      let nScrollTop = 0
      let nDivHight = event.target.offsetHeight // 视口的高度
      nScrollHight = event.target.scrollHeight // 滚动高度
      nScrollTop = event.target.scrollTop // 滚动最上面的距离
      if (nScrollTop + nDivHight + 10 >= nScrollHight) { // 解决不同分辨率下scrollTop的差异
        if (this.pageNo * this.pageSize <= this.total) {
          this.getCourseList()
        }
      }
    },
    goSearch () {
      this.$router.push('/home/search')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../home/item-box.scss';
.page-box {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 5px;
  .search-box {
    width: 346px;
    height: 34px;
    background: #fff;
    border-radius: 17px;
    color: #8F92A9;
    font-size: 13px;
    line-height: 34px;
    text-align: center;
    position: relative;
    // left: 50%;
    // margin-left: -173px;
    // top: 24px;
    margin: 12px auto;
    z-index: 99;
    border: 1px solid #4D78FF;
    .icon {
      position: absolute;
      right: 12px;
      top: 6px;
      width: 20px;
      height: 20px;
      background: url(../../assets/img/hr-search.png) center center no-repeat;
      background-size: 100% 100%;
    }
  }
  .type-box {
    width: 100%;
    display: flex;
    position: relative;
    padding: 0 15px;
    margin-top: 6px;
    .type-item {
      flex: 1;
      margin-right: 10px;
      position: relative;
    }
    .type-item:last-child {
      margin-right: 0;
    }
    img {
      width: 100%;
      height: 65px;
    }
    .type-text-a {
      position: absolute;
      left: 6px;
      top: 8px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
    .type-text-b {
      position: absolute;
      left: 6px;
      bottom: 14px;
      color: #fff;
      font-size: 12px;
    }
  }
  .banner-box {
    width: 340px;
    height: 166px;
    position: relative;
    margin: 0 auto;
    .swiper {
      height: 100%;
      width: 100%;
      position: relative;
      text-align: center;
      line-height: 215px;
      .swiper-item {
        img {
          width: 100%;
          height: 100%;
          position: relative;
        }
      }
      .custom-indicator {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: center;
        height: 20px;
        line-height: 14px;
        cursor: pointer;
        .swiper-indicator {
          width: 10px;
          height: 3px;
          display: inline-block;
          margin: 0 3px;
          background: rgb(190,190,197);
          border-radius: 1.5px;
        }
        .active-indicator {
          background: #282734;
        }
      }
    }
  }

  .active-img {
    margin-top: 2px;
    min-height: 75px;
    padding: 0 16px;
    .van-image {
      width: 100%;
      height: 75px;
    }
  }

  .list-box {
    padding: 5px 0;
    /deep/ .van-tab--active {
      color: #4A77FF;
    }
    /deep/ .van-tabs__line {
      background: #4A77FF;
    }
    .list-title {
      padding: 0 16px 0 28px;
      line-height: 30px;
      font-size: 16px;
      font-weight:bold;
      .title-logo {
        width: 7px;
        height: 22px;
        position: absolute;
        background-color: #7B96FF;
        border-radius: 5px;
        left: 16px;
        top: 9px;
      }
      .more-btn {
        position: absolute;
        right: 11px;
        top: 0;
        color: #9EA5AD;
        padding: 5px;
        font-size: 12px;
        justify-content: center;
        i {
          top: 1px;
        }
      }
    }
    // .list-in-box::before,
    // .list-in-box::after {
    //   content: "";
    //   flex-basis: 100%;
    //   width: 0;
    //   order: 2;
    // }
    .list-in-box {
      width: 100%;
      padding: 6px 6px 6px 16px;
      // column-count: 2;
    }
  }
}
</style>
